<template lang="pug">
  div
    v-data-table(
      :items="items"
      :headers="headers"
      single-expand
    )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.accountCode.account_code }}
          td {{ (item.amount || 0).phpFormat() }}
          td
            v-btn(
              text
              small
              color="yellow darken-2"
              dark
              @click="$emit('input', item)"
            )
              v-icon mdi-pencil
            v-btn(
              text
              x-small
              color="blue-grey"
              @click="expand(!isExpanded)"
            )
              v-icon(:class="isExpanded ? 'is-expanded' : ''") mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(:colspan="headers.length")
            span {{ item.reason }}
</template>
<style lang="sass">
  .is-expanded
    transform: rotate(-180deg)
</style>
<script>
const tableVars = () => ({
  headers: [
    { text: 'Account Code', value: 'accountCode' },
    { text: 'Amount', value: 'amount' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'TableVoucherDetail',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ...tableVars(),
    }
  },
  mixins: [
    require('@/views/admin/vouchers/account-codes/mixin').default,
  ],
}
</script>